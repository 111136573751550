// Access to class types
import { DictionaryItem, DictionaryItemWithParams } from "./useCustomTranslation"

export enum G9ELanguages {
  Farsi = "fa-IR",
	English = "en-US",
	
};

/**
 * @Description The Dictionary object provides easy access to dictionary members for use in a program.
 * @Definition
 * 
 * const t = useCustomTranslation();
 * @Usage1 DictionaryItem Without string Format:
 * 
 * return (<tag>{t(DictionaryKeys.Home())}</tag>)
 * @Usage2 DictionaryItem With string Format:
 * 
 * return (<tag>{t(DictionaryKeys.HomeWithTwoParams("param1", "Param2"))}</tag>)
 */
export const G9Dictionary = {
  
/**
* @Description A dictionary member named "English".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "انگلیسی"
*/
English: () => new DictionaryItem("English"),
/**
* @Description A dictionary member named "Farsi".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "فارسی"
*/
Farsi: () => new DictionaryItem("Farsi"),
/**
* @Description A dictionary member named "Home".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "خانه"
*/
Home: () => new DictionaryItem("Home"),
/**
* @Description A dictionary member named "Menu".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "منو"
*/
Menu: () => new DictionaryItem("Menu"),
/**
* @Description A dictionary member named "Reservation".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "رزرو"
*/
Reservation: () => new DictionaryItem("Reservation"),
/**
* @Description A dictionary member named "CareerOpportunities".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "فرصت های شغلی"
*/
CareerOpportunities: () => new DictionaryItem("CareerOpportunities"),
/**
* @Description A dictionary member named "Suggestions".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "پیشنهادات و انتقادات"
*/
Suggestions: () => new DictionaryItem("Suggestions"),
/**
* @Description A dictionary member named "Date".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "تاریخ"
*/
Date: () => new DictionaryItem("Date"),
/**
* @Description A dictionary member named "TempC".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "درجه سانتی گراد"
*/
TempC: () => new DictionaryItem("TempC"),
/**
* @Description A dictionary member named "TempF".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "درجه فارنهایت"
*/
TempF: () => new DictionaryItem("TempF"),
/**
* @Description A dictionary member named "Summary".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "خلاصه"
*/
Summary: () => new DictionaryItem("Summary"),
/**
* @Description A dictionary member named "Freezing".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "انجماد"
*/
Freezing: () => new DictionaryItem("Freezing"),
/**
* @Description A dictionary member named "Bracing".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "نشاط انگیز"
*/
Bracing: () => new DictionaryItem("Bracing"),
/**
* @Description A dictionary member named "Chilly".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "خنک"
*/
Chilly: () => new DictionaryItem("Chilly"),
/**
* @Description A dictionary member named "Cool".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "سرد"
*/
Cool: () => new DictionaryItem("Cool"),
/**
* @Description A dictionary member named "Mild".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "معتدل"
*/
Mild: () => new DictionaryItem("Mild"),
/**
* @Description A dictionary member named "Warm".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "گرم"
*/
Warm: () => new DictionaryItem("Warm"),
/**
* @Description A dictionary member named "Balmy".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "ملایم"
*/
Balmy: () => new DictionaryItem("Balmy"),
/**
* @Description A dictionary member named "Hot".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "پر حرارت"
*/
Hot: () => new DictionaryItem("Hot"),
/**
* @Description A dictionary member named "Sweltering".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "گرمای شدید"
*/
Sweltering: () => new DictionaryItem("Sweltering"),
/**
* @Description A dictionary member named "Scorching".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "سوزاننده"
*/
Scorching: () => new DictionaryItem("Scorching"),
/**
* @Description A dictionary member named "Scorching2".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "Scorching Two!"
*/
Scorching2: () => new DictionaryItem("Scorching2"),
/**
* @Description A dictionary member named "FirstName".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "نام"
*/
FirstName: () => new DictionaryItem("FirstName"),
/**
* @Description A dictionary member named "LastName".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "نام خانوادگی"
*/
LastName: () => new DictionaryItem("LastName"),
/**
* @Description A dictionary member named "Age".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "سن"
*/
Age: () => new DictionaryItem("Age"),
/**
* @Description A dictionary member named "Birthday".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "تاریخ تولد"
*/
Birthday: () => new DictionaryItem("Birthday"),
/**
* @Description A dictionary member named "FavoriteProgLang".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "زبان های برنامه نویسی مورد علاقه"
*/
FavoriteProgLang: () => new DictionaryItem("FavoriteProgLang"),
/**
* @Description A dictionary member named "Gender".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "جنسیت"
*/
Gender: () => new DictionaryItem("Gender"),
/**
* @Description A dictionary member named "Female".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "زن"
*/
Female: () => new DictionaryItem("Female"),
/**
* @Description A dictionary member named "Male".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "مرد"
*/
Male: () => new DictionaryItem("Male"),
/**
* @Description A dictionary member named "Unknown".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "نامشخص"
*/
Unknown: () => new DictionaryItem("Unknown"),
/**
* @Description A dictionary member named "Information".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "توضیحات"
*/
Information: () => new DictionaryItem("Information"),
/**
* @Description A dictionary member named "ProfileImage".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "تصویر پروفایل"
*/
ProfileImage: () => new DictionaryItem("ProfileImage"),
/**
* @Description A dictionary member named "Submit".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "ارسال"
*/
Submit: () => new DictionaryItem("Submit"),
/**
* @Description A dictionary member named "Input_ErrorMessage_RequiredField".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "فیلد مشخص شده اجباری است"
*/
Input_ErrorMessage_RequiredField: () => new DictionaryItem("Input_ErrorMessage_RequiredField"),
/**
* @Description A dictionary member named "Input_ErrorMessage_MinimumCharacter".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "حداقل کاراکتر برای مقدار وارد شده باید {0} باشد."
* @Params This member has 1 string format parameter.
*/
Input_ErrorMessage_MinimumCharacter: (param1: string) => new DictionaryItemWithParams("Input_ErrorMessage_MinimumCharacter", param1),
/**
* @Description A dictionary member named "Input_ErrorMessage_MaximumCharacter".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "حداکثر کاراکتر برای مقدار وارد شده باید {0} باشد."
* @Params This member has 1 string format parameter.
*/
Input_ErrorMessage_MaximumCharacter: (param1: string) => new DictionaryItemWithParams("Input_ErrorMessage_MaximumCharacter", param1),
/**
* @Description A dictionary member named "Input_ErrorMessage_MinimumNumber".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "حداقل عدد وارد شده باید مساوی یا بزرگتر از {0} باشد."
* @Params This member has 1 string format parameter.
*/
Input_ErrorMessage_MinimumNumber: (param1: string) => new DictionaryItemWithParams("Input_ErrorMessage_MinimumNumber", param1),
/**
* @Description A dictionary member named "Input_ErrorMessage_MaximumNumber".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "حداکثر عدد وارد شده باید برابر یا کمتر از {0} باشد."
* @Params This member has 1 string format parameter.
*/
Input_ErrorMessage_MaximumNumber: (param1: string) => new DictionaryItemWithParams("Input_ErrorMessage_MaximumNumber", param1),
/**
* @Description A dictionary member named "Input_ErrorMessage_FieldMustBeNumber".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "مقدار وارد شده باید یک عدد باشد."
*/
Input_ErrorMessage_FieldMustBeNumber: () => new DictionaryItem("Input_ErrorMessage_FieldMustBeNumber"),
/**
* @Description A dictionary member named "Input_ErrorMessage_PasswordValidation".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "رمز عبور باید ترکیبی از کاراکترهای بزرگ، کوچک، اعداد و حداقل یکی از کاراکترهای خاص (@$!%*؟&) باشد که طول آن حداقل {0} و حداکثر {1} کاراکتر باشد."
* @Params This member has 2 string format parameters.
*/
Input_ErrorMessage_PasswordValidation: (param1: string,param2: string) => new DictionaryItemWithParams("Input_ErrorMessage_PasswordValidation", param1,param2),
/**
* @Description A dictionary member named "Input_ErrorMessage_FieldMustBeInteger".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "مقدار وارد شده باید یک عدد صحیح باشد."
*/
Input_ErrorMessage_FieldMustBeInteger: () => new DictionaryItem("Input_ErrorMessage_FieldMustBeInteger"),
/**
* @Description A dictionary member named "Input_ErrorMessage_FieldMustBeIntegerOrDecimal".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "مقدار وارد شده باید یک عدد صحیح یا اعشاری باشد. اگر یک عدد اعشاری وارد کرده اید، تعداد قسمت اعشاری باید بیشتر از {0} و کمتر از {1} باشد."
* @Params This member has 2 string format parameters.
*/
Input_ErrorMessage_FieldMustBeIntegerOrDecimal: (param1: string,param2: string) => new DictionaryItemWithParams("Input_ErrorMessage_FieldMustBeIntegerOrDecimal", param1,param2),
/**
* @Description A dictionary member named "Input_ErrorMessage_CheckBoxItemsAreRequired".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "موارد چک باکس الزامی است و باید انتخاب شود."
*/
Input_ErrorMessage_CheckBoxItemsAreRequired: () => new DictionaryItem("Input_ErrorMessage_CheckBoxItemsAreRequired"),
/**
* @Description A dictionary member named "Input_ErrorMessage_MinimumMemberMustBeSelected".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "حداقل {0} عضو از این لیست باید انتخاب شوند."
* @Params This member has 1 string format parameter.
*/
Input_ErrorMessage_MinimumMemberMustBeSelected: (param1: string) => new DictionaryItemWithParams("Input_ErrorMessage_MinimumMemberMustBeSelected", param1),
/**
* @Description A dictionary member named "Input_ErrorMessage_MaximaumMemberOfCheckBox".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "حداکثر {0} عضو از این لیست را می توان انتخاب کرد."
* @Params This member has 1 string format parameter.
*/
Input_ErrorMessage_MaximaumMemberOfCheckBox: (param1: string) => new DictionaryItemWithParams("Input_ErrorMessage_MaximaumMemberOfCheckBox", param1),
/**
* @Description A dictionary member named "Input_ErrorMessage_FieldMustBeDate".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "مقدار وارد شده باید یک تاریخ باشد."
*/
Input_ErrorMessage_FieldMustBeDate: () => new DictionaryItem("Input_ErrorMessage_FieldMustBeDate"),
/**
* @Description A dictionary member named "Input_ErrorMessage_MinimumChosenDate".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "حداقل تاریخ انتخابی نباید کمتر از {0} باشد."
* @Params This member has 1 string format parameter.
*/
Input_ErrorMessage_MinimumChosenDate: (param1: string) => new DictionaryItemWithParams("Input_ErrorMessage_MinimumChosenDate", param1),
/**
* @Description A dictionary member named "Input_ErrorMessage_MaximumChosenDate".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "حداکثر تاریخ انتخابی نباید بیشتر از {0} باشد."
* @Params This member has 1 string format parameter.
*/
Input_ErrorMessage_MaximumChosenDate: (param1: string) => new DictionaryItemWithParams("Input_ErrorMessage_MaximumChosenDate", param1),
/**
* @Description A dictionary member named "Slider_Text1".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "غذای خوشمزه در فضایی دنج"
*/
Slider_Text1: () => new DictionaryItem("Slider_Text1"),
/**
* @Description A dictionary member named "Slider_Text2".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "جایی که هر طعمی داستانی را بیان می کند"
*/
Slider_Text2: () => new DictionaryItem("Slider_Text2"),
/**
* @Description A dictionary member named "Slider_Text3".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "ما همیشه در انتظار شما هستیم"
*/
Slider_Text3: () => new DictionaryItem("Slider_Text3"),
/**
* @Description A dictionary member named "Slider_Text4".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "از آشپزخانه ما تا قلب شما"
*/
Slider_Text4: () => new DictionaryItem("Slider_Text4"),
/**
* @Description A dictionary member named "Slider_Text5".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "با طعم هر لقمه، خاطرات ماندگار بسازید"
*/
Slider_Text5: () => new DictionaryItem("Slider_Text5"),
/**
* @Description A dictionary member named "Slider_Text6".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "جایی که طعم ها، با خاطرات ملاقات می کند"
*/
Slider_Text6: () => new DictionaryItem("Slider_Text6"),
/**
* @Description A dictionary member named "Opening".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "افتتاحیه"
*/
Opening: () => new DictionaryItem("Opening"),
/**
* @Description A dictionary member named "OpeningContentText".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "با سلام، 
    بیش از سه سال از سفر شگفت‌آور پروژه نوفه سپری شده است، و این سفر همچنان ادامه دارد. با وجود چالش‌ها و نقاط ضعف کوچک، ما به عنوان میزبانانی از طراحی و ذوق، آماده‌ایم تا شما را در دنیای خودتان به مهمانی بی‌نظیری دعوت کنیم. از چهارشنبه ۱۷ آبان، از ساعت ۷ صبح تا ۱۱:۳۰ شب، منتظر حضور شما خواهیم بود. امیدواریم که بتوانیم شما را در نوفه به جایی عمیق و لذت‌بخش ببریم و تجربه‌ای لذت‌بخش و فراموش نشدنی برای شما ایجاد کنیم."
*/
OpeningContentText: () => new DictionaryItem("OpeningContentText"),
/**
* @Description A dictionary member named "Pasta".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "پاستا"
*/
Pasta: () => new DictionaryItem("Pasta"),
/**
* @Description A dictionary member named "PastaContentText".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "در دیدار مهمانان گرانقدری که به دل نوفه پا گذاشته‌اند، این احساس حمایت و ارزشمندی بی‌شمار می‌باشد. ما با تمام وجود و انگیزه خود، در این فضای جادویی، کوشش می‌نماییم تا رازهای زیبایی و برترین‌ها را برای شما آشکار سازیم. در هر زاویه، در هر ذره نور، ما از زیبایی‌هایی که در این نقطه تلاقی یافته‌اند می‌سخن گوییم. می‌خواهیم اینجا را به یک نقطه‌ی ملاقات خاص و دلنشین تبدیل کنیم، جایی که هر لحظه یک خاطره فراموش‌نشدنی خواهد بود."
*/
PastaContentText: () => new DictionaryItem("PastaContentText"),
/**
* @Description A dictionary member named "Carbonara".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "کربونارا"
*/
Carbonara: () => new DictionaryItem("Carbonara"),
/**
* @Description A dictionary member named "CarbonaraContentText".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "در این آشپزخانه جادویی، هنر و شعور به یکدیگر آمیخته و یک اثر بی‌نظیر به نام کربونارا به وجود می‌آید. هماهنگی دقیق بین پاستاهای آب‌پز و سسی کرمی باطله و تکه‌های بیکن به تحتانی مختصر که به نام کربونارا شهرت دارد. این غذا با طعم و رایحه‌ای که هرگز فراموش نمی‌شود، قلب هر عاشق سفر به ایتالیا را فرا می‌خواند. با هر قاشق زنده‌ای، شما در دنیایی عمیق و خوشایند غرق خواهید شد، جایی که هر لحظه یک تجربه‌ی لذت‌بخش خواهد بود."
*/
CarbonaraContentText: () => new DictionaryItem("CarbonaraContentText"),
/**
* @Description A dictionary member named "Fettuccine".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "فتوچینی"
*/
Fettuccine: () => new DictionaryItem("Fettuccine"),
/**
* @Description A dictionary member named "FettuccineContentText".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "اطلاله‌ای غربی و مهیج از ایتالیا در هر ربع وعده غذایی به ما روایت می‌کند. آنجا که طعم و عطرهای بی‌انتها در هر غذا به یک داستان خوش‌مزه مبدل می‌شوند. اما فتوچینی با سس پستو، در این غذا با ارزش سادگی، عمقی نو و خاص می‌افزاید. هنر و جادوی آشپزی ایتالیایی در هر تکه‌ی نودل و هر قطره‌ی این سس، به شکلی کاملاً جدید و شگفت‌انگیز به رخ می‌پردازد. این یک زمینه‌ی بی‌پایان برای لذت بردن از غذا و همچنین یادگیری از فرهنگ گرم و دلنشین ایتالیاست."
*/
FettuccineContentText: () => new DictionaryItem("FettuccineContentText"),
/**
* @Description A dictionary member named "Nufe".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "نوفه"
*/
Nufe: () => new DictionaryItem("Nufe"),
/**
* @Description A dictionary member named "NufeContentText".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "در نوفه، اساس‌های آشپزی به عنوان چرخه‌ی اصلی برای طراحی منو در نظر گرفته شده‌اند. این برنامه‌ریزی دقیق به ما این امکان را می‌دهد تا یک تجربه‌ی مذاقی منحصر به فرد را برای شما رقم بزنیم. از زمان انتخاب مواد تا لحظه‌ی پردازش حرفه‌ای آن‌ها، هر گام در این مسیر، نشان‌دهنده‌ی تمرکز ما بر کیفیت و لذت در هر ارائه است. هدف ما این است که هر غذا را به یک سفر مهیج و لذت‌بخش تبدیل کنیم، جایی که حس لذت و تازگی هر لحظه در ذائقه‌های شما جاری شود."
*/
NufeContentText: () => new DictionaryItem("NufeContentText"),
/**
* @Description A dictionary member named "ReferToTheMainArticle".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "مراجعه به مطلب اصلی"
*/
ReferToTheMainArticle: () => new DictionaryItem("ReferToTheMainArticle"),
/**
* @Description A dictionary member named "SampleFullDate".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "سه شنبه، 16ام آبان ماه"
*/
SampleFullDate: () => new DictionaryItem("SampleFullDate"),
/**
* @Description A dictionary member named "RestaurantMenu".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "منوی رستوران"
*/
RestaurantMenu: () => new DictionaryItem("RestaurantMenu"),
/**
* @Description A dictionary member named "Appetizer".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "پیش غذا و سالاد"
*/
Appetizer: () => new DictionaryItem("Appetizer"),
/**
* @Description A dictionary member named "Breakfast".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "صبحانه"
*/
Breakfast: () => new DictionaryItem("Breakfast"),
/**
* @Description A dictionary member named "MainCourse".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "غذای اصلی"
*/
MainCourse: () => new DictionaryItem("MainCourse"),
/**
* @Description A dictionary member named "Pizza".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "پیتزا"
*/
Pizza: () => new DictionaryItem("Pizza"),
/**
* @Description A dictionary member named "Sandwiches".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "ساندویچ"
*/
Sandwiches: () => new DictionaryItem("Sandwiches"),
/**
* @Description A dictionary member named "Burgers".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "برگر"
*/
Burgers: () => new DictionaryItem("Burgers"),
/**
* @Description A dictionary member named "Dessert".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "دسر"
*/
Dessert: () => new DictionaryItem("Dessert"),
/**
* @Description A dictionary member named "TeaAndHotDrinks".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "چای و نوشیدنی های گرم"
*/
TeaAndHotDrinks: () => new DictionaryItem("TeaAndHotDrinks"),
/**
* @Description A dictionary member named "ColdDrink".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "نوشیدنی سرد"
*/
ColdDrink: () => new DictionaryItem("ColdDrink"),
/**
* @Description A dictionary member named "ShakeAndFrappe".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "شیک و فراپه"
*/
ShakeAndFrappe: () => new DictionaryItem("ShakeAndFrappe"),
/**
* @Description A dictionary member named "Coffee".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "قهوه"
*/
Coffee: () => new DictionaryItem("Coffee"),
/**
* @Description A dictionary member named "Additive".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "افزودنی"
*/
Additive: () => new DictionaryItem("Additive"),
/**
* @Description A dictionary member named "Ingredient".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "مواد تشکیل دهنده:"
*/
Ingredient: () => new DictionaryItem("Ingredient"),
/**
* @Description A dictionary member named "RoastPotatoes".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "سیب زمینی تنوری"
*/
RoastPotatoes: () => new DictionaryItem("RoastPotatoes"),
/**
* @Description A dictionary member named "Homs".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "حُمص"
*/
Homs: () => new DictionaryItem("Homs"),
/**
* @Description A dictionary member named "HomsIng".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "نخود، ارده، روغن زیتون فرابکر"
*/
HomsIng: () => new DictionaryItem("HomsIng"),
/**
* @Description A dictionary member named "BeanDip".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "دیپ لوبیا"
*/
BeanDip: () => new DictionaryItem("BeanDip"),
/**
* @Description A dictionary member named "BeanDipIng".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "لوبیا سفید، رزماری، روغن زیتون فرابکر"
*/
BeanDipIng: () => new DictionaryItem("BeanDipIng"),
/**
* @Description A dictionary member named "GrilledEggplantDip".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "دیپ بادمجان کبابی"
*/
GrilledEggplantDip: () => new DictionaryItem("GrilledEggplantDip"),
/**
* @Description A dictionary member named "GrilledEggplantDipIng".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "بادمجان کبابی، ماست، ارده، روغن زیتون فرابکر"
*/
GrilledEggplantDipIng: () => new DictionaryItem("GrilledEggplantDipIng"),
/**
* @Description A dictionary member named "Zatziki".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "زاتزیکی"
*/
Zatziki: () => new DictionaryItem("Zatziki"),
/**
* @Description A dictionary member named "ZatzikiIng".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "ماست یونانی، خیار، سبزیجات"
*/
ZatzikiIng: () => new DictionaryItem("ZatzikiIng"),
/**
* @Description A dictionary member named "VegetableSoup".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "سوپ سبزیجات"
*/
VegetableSoup: () => new DictionaryItem("VegetableSoup"),
/**
* @Description A dictionary member named "VegetableSoupIng".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "سوپ سبزیجات"
*/
VegetableSoupIng: () => new DictionaryItem("VegetableSoupIng"),
/**
* @Description A dictionary member named "GrilledCheeseSandwich".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "تست پنیر گریل شده"
*/
GrilledCheeseSandwich: () => new DictionaryItem("GrilledCheeseSandwich"),
/**
* @Description A dictionary member named "GrilledCheeseSandwichIng".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "نان خمیرترش، بلوچیز، پنیر موزارلا"
*/
GrilledCheeseSandwichIng: () => new DictionaryItem("GrilledCheeseSandwichIng"),
/**
* @Description A dictionary member named "AAAAAAAAAAA".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "AAAAAAAAAAA"
*/
AAAAAAAAAAA: () => new DictionaryItem("AAAAAAAAAAA"),
/**
* @Description A dictionary member named "LatestPosts".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "آخرین پست ها"
*/
LatestPosts: () => new DictionaryItem("LatestPosts"),
/**
* @Description A dictionary member named "PleaseWait".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "لطفا صبر کنید"
*/
PleaseWait: () => new DictionaryItem("PleaseWait"),
/**
* @Description A dictionary member named "UnderConstruction".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "در دست ساخت"
*/
UnderConstruction: () => new DictionaryItem("UnderConstruction"),
/**
* @Description A dictionary member named "ComingSoon".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "به زودی"
*/
ComingSoon: () => new DictionaryItem("ComingSoon"),
/**
* @Description A dictionary member named "Price".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "{0}"
* @Params This member has 1 string format parameter.
*/
Price: (param1: string) => new DictionaryItemWithParams("Price", param1),
/**
* @Description A dictionary member named "ContactAndHelp".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "راه های تماس با ما"
*/
ContactAndHelp: () => new DictionaryItem("ContactAndHelp"),
/**
* @Description A dictionary member named "FollowUs".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "ما در شبکه های اجتماعی"
*/
FollowUs: () => new DictionaryItem("FollowUs"),
/**
* @Description A dictionary member named "All".
* The sample value of this member for the default language "fa-IR" is coming below:
* @Sample "همه"
*/
All: () => new DictionaryItem("All"),
}
