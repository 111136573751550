import "./PageOrderMenu.scss";
import { useCustomTranslation } from "../../Languages/useCustomTranslation";
import { MenuData } from "./MenuData";

export const PageOrderMenu = () => {
  const { translator } = useCustomTranslation();

  return (
    <MenuData />
  );
};

PageOrderMenu.displayName = "Menu";
