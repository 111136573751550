// Create a new file beside the index.js file named i18n.js Here we can specify the languages that we want to support.
// The first two libraries will do the heavy-lifting for us.
// npm install react-i18next i18next
// The following package will detect the language automatically for us. So don’t have to worry about determining the currently selected language
// npm install i18next-browser-languagedetector
// The next package will load the values depending on the language returned by the language detector.
// npm install i18next-xhr-backend

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

const fallbackLng = "fa-IR";

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: fallbackLng,
    lng: fallbackLng,
    detection: {
      checkWhitelist: true,
    },
    debug: false,
    whitelist: ["fa-IR","en-US"],
    interpolation: {
      escapeValue: false, // no need for react. it escapes by default
    },
  });



export default i18n;
