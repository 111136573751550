import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "./Components/Layout/Layout";
import { AppRoutes } from "./AppRoutes";

// Multilanguage
// we have to modify our App.js file and include our configuration file.
import "./Languages/i18n";
import { useCustomTranslation } from "./Languages/useCustomTranslation";
import { PageHome2 } from "./Pages/Home2/PageHome2";
import { PageOrderMenu } from "./Pages/OrderMenu/PageOrderMenu";
import { G9ELanguages } from "./Languages/G9Dictionary";

function App() {
  const { language } = useCustomTranslation();
  return (
    // Multilanguage
    // Notice we used <Suspense> here as react-i18next loads local resources asynchronously and we have to wait to finish loading
    <>
      {/* <PageHome2 /> */}
      <Suspense fallback={null}>
        {/* <Layout lang={language}>
          <Routes>
            {AppRoutes.map((route, index) => {
              const { element, ...rest } = route;
              let data = <Route key={index} {...rest} element={element} />;
              return data;
            })}
          </Routes>
        </Layout> */}

        <div
          id="MainStarterDiv"
          style={{
            minHeight: "100vh",
            direction: language === G9ELanguages.English ? "ltr" : "rtl",
          }}
          dir={language === G9ELanguages.English ? "ltr" : "rtl"}
        >
          <PageOrderMenu />
        </div>
        
      </Suspense>
    </>
  );
}

export default App;
