import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { format } from "react-string-format";
import i18n from "./i18n";
import { _DefaultNamespace } from "react-i18next/TransWithoutContext";
import { FlatNamespace, Namespace, TFunction } from "i18next";
import { G9ELanguages } from "./G9Dictionary";

/**
 * @Description A value type class for dictionary item without string format parameters
 */
export class DictionaryItem {
    DictionaryItem: string;

    constructor(DicItem: string) {
        this.DictionaryItem = DicItem;
    }

    public Translate(forceTranslateToCustomLanguage?: G9ELanguages): string {
        return G9CustomTranslator.GetTranslationInCustomLangByStringKey(this.DictionaryItem, forceTranslateToCustomLanguage);
    }
}

/**
 * @Description A value type class for dictionary item with string format parameters
 */
export class DictionaryItemWithParams extends DictionaryItem {
    DictionaryParams: string[]

    constructor(DicItem: string, ...param: string[]) {
        super(DicItem);
        this.DictionaryParams = param;
    }

    public Translate(forceTranslateToCustomLanguage?: G9ELanguages): string {
        return G9CustomTranslator.GetTranslationInCustomLangByStringKey(this.DictionaryItem, forceTranslateToCustomLanguage, ...this.DictionaryParams);
    }
}

/**
 * @Description <br />
 * A custom hook for customizing translation
 * @param {(DictionaryKey: DictionaryItem | DictionaryItemWithParams) => string} translator 
 * -- Function for using to translate a dictionary key to the current language
 * @param {string} language 
 * -- Specifies the current language (like 'en' or 'fa')
 * @Definition <br />
 * const {translator, language} = useCustomTranslation();
 * @Usage1 -- DictionaryItem Without string Format: <br />
 * return (<tag>{translator(DictionaryKeys.Home())}</tag>)
 * @Usage2 -- DictionaryItem With string Format: <br />
 * return (<tag>{translator(DictionaryKeys.HomeWithTwoParams("param1", "Param2"))}</tag>)
 */
export function useCustomTranslation() {

    const { t } = useTranslation();
    const [language, setLanguage] = useState<G9ELanguages>((i18n.language as G9ELanguages));
    useEffect(() => {
        if (i18n.language !== language)
            setLanguage((i18n.language as G9ELanguages));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18n.language])

    const translator = function translator(DictionaryKey: DictionaryItem | DictionaryItemWithParams, forceTranslateToCustomLanguage?: G9ELanguages) {
        return translate(t, DictionaryKey, forceTranslateToCustomLanguage);
    };

    const translatorByStringKey = function translator(DictionaryKey: string, forceTranslateToCustomLanguage?: G9ELanguages, ...param: string[]) {
        return translateByStringKey(t, DictionaryKey, forceTranslateToCustomLanguage, ...param);
    };

    return { translator, translatorByStringKey, language };
}


export abstract class G9CustomTranslator {

    public static GetCurrentLanguage(): G9ELanguages { return (i18n.language as G9ELanguages) };

    public static GetTeranslationInCustomLang(DictionaryKey: DictionaryItem | DictionaryItemWithParams,
        forceTranslateToCustomLanguage?: G9ELanguages) {
        const t = i18n.t;
        return translate(t, DictionaryKey, forceTranslateToCustomLanguage)
    }

    public static GetTranslationInCustomLangByStringKey(DictionaryKey: string,
        forceTranslateToCustomLanguage?: G9ELanguages,
        ...param: string[]) {
        const t = i18n.t;
        return translateByStringKey(t, DictionaryKey, forceTranslateToCustomLanguage, ...param);
    }
}

function translate(t: TFunction<[_DefaultNamespace, ...Exclude<FlatNamespace, _DefaultNamespace>[]]> | TFunction<Namespace, any>,
    DictionaryKey: DictionaryItem | DictionaryItemWithParams,
    forceTranslateToCustomLanguage?: G9ELanguages) {

    if ((DictionaryKey instanceof DictionaryItemWithParams) &&
        (DictionaryKey as DictionaryItemWithParams).DictionaryParams.length > 0) {
        if (forceTranslateToCustomLanguage)
            return format(t(DictionaryKey.DictionaryItem, { lng: forceTranslateToCustomLanguage }),
                ...(DictionaryKey as DictionaryItemWithParams).DictionaryParams)
        else
            return format(t(DictionaryKey.DictionaryItem),
                ...(DictionaryKey as DictionaryItemWithParams).DictionaryParams)
    }
    else {
        if (forceTranslateToCustomLanguage)
            return t(DictionaryKey.DictionaryItem, { lng: forceTranslateToCustomLanguage });
        else
            return t(DictionaryKey.DictionaryItem);
    }
}

function translateByStringKey(t: TFunction<[_DefaultNamespace, ...Exclude<FlatNamespace, _DefaultNamespace>[]]> | TFunction<Namespace, any>,
    DictionaryKey: string,
    forceTranslateToCustomLanguage?: string,
    ...param: string[]) {

    if (param && param.length > 0) {
        if (forceTranslateToCustomLanguage)
            return format(t(DictionaryKey, { lng: forceTranslateToCustomLanguage }), ...param);
        else
            return format(t(DictionaryKey), ...param);
    } else {
        if (forceTranslateToCustomLanguage)
            return t(DictionaryKey, { lng: forceTranslateToCustomLanguage });
        else
            return t(DictionaryKey);
    }
}
